import React, { useEffect, useRef, useState } from "react"
import { apiSelectors } from "../../store/reducers/apiReducer"
import { useSelector } from "react-redux"
import { IApiDocPathItem, IApiDocRoute } from "../../type/api/apiDocTypes"
import "./ApiRoute.scss"
import CodeEditor from "../code/CodeEditor"
import useDimensions from "react-cool-dimensions"
import ApiRouteName from "./ApiRouteName"
import SwiipeBox from "../box/SwiipeBox"
import ApiRouteParam from "./ApiRouteParam"
import SwiipeFrame from "../box/SwiipeFrame"
import { extractRouteResponses, extractRouteRequest, getSchemaSubItems, getApiRouteKey } from "../../services/apiRouteService"
import ApiRouteParamGroup from "./ApiRouteParamGroup"
import { sortAscending } from "../../util/arrayUtil"
import { IRouteGroup } from "../../services/apiGroupService"
import ResolvedContent from "../content/ResolvedContent"

interface IApiRouteProps {
    route: IApiDocRoute
    group: IRouteGroup
    doc: IApiDocPathItem
    onPotentialFocus: (percent: number, group: string, route?: string) => void
}

const ApiRoute = ({ route, doc, group, onPotentialFocus }: IApiRouteProps) => {
    const [codeWidth, setCodeWidth] = useState<number | undefined>(undefined)
    const timerRef = useRef<NodeJS.Timeout>()

    const elem = useDimensions({
        onResize: ({ width }) => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
            timerRef.current = setTimeout(() => {
                setCodeWidth(width)
            }, 100)
        },
    })

    const selection = useSelector(apiSelectors.getApiSelection)
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (selection?.selectedRoute === getApiRouteKey(route) && selection.selectedBy !== "scrolling") {
            const scrollTop = document.documentElement.scrollTop as number
            const rect = elem.entry?.target?.getBoundingClientRect()
            document.documentElement.scrollTo({
                top: scrollTop + (rect?.top ?? 0) - 50,
                behavior: "smooth",
            })
        }
    }, [selection])

    useEffect(() => {
        const onScroll = (e: any) => {
            const rect = containerRef.current?.getBoundingClientRect()
            const windowHeight = e.target.documentElement.clientHeight
            const areaStart = windowHeight * 0.45
            const areaEnd = windowHeight * 0.55

            if (rect && rect.top < areaEnd && rect.bottom > areaStart) {
                // Some percentage
                const topInner = rect.top < areaStart ? areaStart : rect.top
                const bottomInner = rect.bottom > areaEnd ? areaEnd : rect.bottom
                const percent = (bottomInner - topInner) / areaEnd
                onPotentialFocus(percent, group.id, getApiRouteKey(route))
            }
        }
        window.addEventListener("scroll", onScroll)
        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    // if (route.pathTemplate !== "/v1/locations/ipAddress/me") {
    //     return null
    // }

    const request = extractRouteRequest(doc)
    const responses = extractRouteResponses(doc)
    const responsesWithExample = sortAscending(
        responses.filter((r) => r.example),
        (r) => r.code
    )

    return (
        <div ref={containerRef} className="api-route-comp">
            <div className="left" ref={elem.observe}>
                <div className="route-name">
                    <SwiipeFrame blackwhite>
                        <ApiRouteName route={route} />
                    </SwiipeFrame>
                </div>
                <div className="section">{doc.summary}</div>
                <p>
                    <ResolvedContent content={doc.description ?? ""} />
                </p>
                <ApiRouteParamGroup params={request.parameters} title="Parameters" description="" />
                {responses.map((resp) => {
                    return (
                        <ApiRouteParamGroup
                            key={resp.code}
                            params={resp.parameters}
                            title={"Response: " + resp.code}
                            description={resp.description ?? ""}
                            mustBeOpened={!resp.code.startsWith("20")}
                            isResponse
                        />
                    )
                })}
            </div>
            <div className="right">
                {request.example && (
                    <div className="example">
                        <SwiipeBox invert title={"Request example"} padding={0}>
                            <CodeEditor
                                width={codeWidth}
                                code={JSON.stringify(request.example, undefined, 2)}
                                language="json"
                                maxHeight={300}
                            />
                        </SwiipeBox>
                    </div>
                )}
                {responsesWithExample.map((response) => {
                    return (
                        <div className="example" key={response.code}>
                            <SwiipeBox title={"Response example: " + response.code} padding={0}>
                                <CodeEditor
                                    width={codeWidth}
                                    code={JSON.stringify(response.example, undefined, 2)}
                                    language="json"
                                    maxHeight={300}
                                />
                            </SwiipeBox>
                        </div>
                    )
                })}
                {/* <div className="response-example" key="SwaggerData">
                    <SwiipeBox title="Swagger Data" padding={0}>
                        <CodeEditor width={codeWidth} code={JSON.stringify(doc, undefined, 2)} language="json" maxHeight={300} />
                    </SwiipeBox>
                </div> */}
            </div>
        </div>
    )
}

export default ApiRoute
