import { IApiDoc, IApiDocPath, IApiDocRoute, THttpMethod } from "../type/api/apiDocTypes"
import { getDistinct, hasValue } from "../util/arrayUtil"
export function cleanApiDoc(doc: IApiDoc, routes: IApiDocRoute[], endpoint: string) {
    const newDoc = createNewDocWithIncludedRoutes(doc, routes, endpoint)
    const selectorsFromPaths = getDistinct(findSelectors(Object.values(newDoc.paths)))
    newDoc.components = doc.components
    removeUnusedComponentsFromSelectors(newDoc, selectorsFromPaths)
    return newDoc
}

export function replaceSelectorsWithMemoryReferences(doc: IApiDoc) {
    replaceSelectorsWithMemoryReferencesForObj(doc, doc.paths)
    replaceSelectorsWithMemoryReferencesForObj(doc, doc.components)
    return doc
}

function replaceSelectorsWithMemoryReferencesForObj(doc: IApiDoc, obj: any) {
    Object.keys(obj).forEach((key) => {
        const value = obj[key]
        if (value && typeof value === "object") {
            if (value.$ref && typeof value.$ref === "string" && (value.$ref as string).startsWith("#/")) {
                const comp = findComponentFromSelector(doc, value.$ref)
                if (comp) {
                    comp.componentName = (value.$ref as string).split("/").pop()
                } else {
                    console.warn("Could not find component for selector", value.$ref, doc.endpoint)
                }
                obj[key] = comp
            } else {
                replaceSelectorsWithMemoryReferencesForObj(doc, value)
            }
        }
    })
}

// Only keep the components that are referenced from the given selectors
// NB: A component can reference another component that can reference another component...
function removeUnusedComponentsFromSelectors(doc: IApiDoc, selectors: string[]) {
    // Find all possible selectors from initial selectors
    let resultSelectors = [...selectors]
    let searchSelectors = [...selectors]
    const keepRunning = true
    while (keepRunning) {
        const comps = searchSelectors.map((s) => findComponentFromSelector(doc, s)).filter(hasValue)
        const foundSelectors = findSelectors(comps)
        const newSelectors = foundSelectors.filter((ns) => !resultSelectors.includes(ns))
        if (newSelectors.length === 0) {
            break
        }
        searchSelectors = newSelectors
        resultSelectors = [...resultSelectors, ...newSelectors]
    }

    const allComps = resultSelectors.map((s) => findComponentFromSelector(doc, s))
    if (doc.components.schemas) {
        Object.keys(doc.components.schemas).forEach((key) => {
            if (!allComps.includes(doc.components.schemas![key])) {
                delete doc.components.schemas![key]
            }
        })
    }
    if (doc.components.responses) {
        Object.keys(doc.components.responses).forEach((key) => {
            if (!allComps.includes(doc.components.responses![key])) {
                delete doc.components.responses![key]
            }
        })
    }
    if (doc.components.parameters) {
        Object.keys(doc.components.parameters).forEach((key) => {
            if (!allComps.includes(doc.components.parameters![key])) {
                delete doc.components.parameters![key]
            }
        })
    }
    if (doc.components.headers) {
        Object.keys(doc.components.headers).forEach((key) => {
            if (!allComps.includes(doc.components.headers![key])) {
                delete doc.components.headers![key]
            }
        })
    }
    if (doc.components.links) {
        Object.keys(doc.components.links).forEach((key) => {
            if (!allComps.includes(doc.components.links![key])) {
                delete doc.components.links![key]
            }
        })
    }
}

function findSelectors(objs: any[], foundSelectors?: string[] | undefined) {
    if (!foundSelectors) {
        foundSelectors = []
    }
    objs.filter(hasValue).forEach((o) => {
        if (typeof o === "object") {
            if (o.$ref && typeof o.$ref === "string" && (o.$ref as string).startsWith("#/")) {
                foundSelectors!.push(o.$ref)
            }
            findSelectors(Object.values(o), foundSelectors)
        }
    })
    return foundSelectors
}

function findComponentFromSelector(doc: IApiDoc, selector: string): any | undefined {
    const parts = selector.split("/")
    // Skip # level
    let obj = doc
    for (let i = 1; i < parts.length; i++) {
        obj = obj[parts[i]]
        if (!obj) {
            return undefined
        }
    }
    return obj
}

function createNewDocWithIncludedRoutes(doc: IApiDoc, routes: IApiDocRoute[], endpoint: string) {
    const usedPaths: { docPathStr: string; routePathStr: string; method: THttpMethod; overrideMethod?: THttpMethod }[] = []
    routes.forEach((r) => {
        const pathStr = findMatchingPath(r.internalApiPath, r.method, doc)
        // log this out, if some routes are not showing up
        //console.log(r.internalApiPath, r.method, doc)
        if (
            pathStr &&
            !usedPaths.find(
                (up) =>
                    up.routePathStr === r.pathTemplate &&
                    up.docPathStr === pathStr &&
                    up.method === r.method &&
                    up.overrideMethod === r.overrideExternalMethod
            )
        ) {
            usedPaths.push({
                docPathStr: pathStr,
                routePathStr: r.pathTemplate,
                method: r.method,
                overrideMethod: r.overrideExternalMethod,
            })
        }
    })

    const newDoc: IApiDoc = {
        openapi: doc.openapi,
        info: doc.info,
        paths: {},
        components: {},
        endpoint: endpoint,
    }
    usedPaths.forEach((up) => {
        let path: IApiDocPath = newDoc.paths[up.routePathStr]
        if (!path) {
            path = {}
            newDoc.paths[up.routePathStr] = path
        }
        path[up.overrideMethod || up.method] = doc.paths[up.docPathStr][up.method]
        if (up.overrideMethod && up.overrideMethod !== up.method && (up.overrideMethod === "get" || up.method === "get")) {
            // TODO: Convert to from get requires changing between body/params
        }
    })

    return newDoc
}

function findMatchingPath(pathStr: string, method: THttpMethod, doc: IApiDoc) {
    const pathsStr = Object.keys(doc.paths)
    // Filter the paths that contains the right method
    const pathsStrWithMethod = pathsStr.filter((p) => !!doc.paths[p][method])

    // TODO: We might need more advanced logic for matching paths
    return pathsStrWithMethod.find((pStr) => (pStr ?? "").toLowerCase() === (pathStr ?? "").toLowerCase())
}
