import { toDictSelectValue } from "../util/arrayUtil"
import { IContentItem, IServerContentItem } from "../type/doc/IContent"
export function getContentSortValue(item: IContentItem) {
    return parseInt(item.locationInParent)
}

export function mapFromServerContentItems(server: IServerContentItem[]): IContentItem {
    const first = server[0]
    return {
        itemId: first.itemId,
        languages: toDictSelectValue(
            server,
            (i) => i.language,
            (i) => i.content
        ),
        locationInParent: first.locationInParent,
        parentId: first.parentId,
        type: first.type,
    }
}

export function mapToServerContentItems(item: IContentItem): IServerContentItem[] {
    const serverItems: IServerContentItem[] = Object.keys(item.languages).map((l) => {
        return {
            content: item.languages[l],
            itemId: item.itemId,
            language: l,
            locationInParent: item.locationInParent,
            parentId: item.parentId,
            type: item.type,
            mustDelete: item.mustDelete,
        }
    })
    return serverItems
}
