import React from "react"
import "./ContentAdder.scss"
import { addUnsavedItemThunk } from "../../store/thunks/contentThunks"
import StandardButton from "../buttons/StandardButton"
import useReduxDispatch from "../../store/useReduxDispatch"

interface IContentAdderProps {
    parentId: string
    sortValBefore: number
    sortValAfter: number
}

const ContentAdder = ({ sortValBefore, sortValAfter, parentId }: IContentAdderProps) => {
    const dispatch = useReduxDispatch()
    const newSortValue = Math.ceil(sortValBefore + (sortValAfter - sortValBefore) / 2)
    return (
        <div
            className="content-adder-comp"
            onClick={() => {
                console.log(parentId, "RichText", newSortValue + "")
                dispatch(addUnsavedItemThunk(parentId, "RichText", newSortValue + ""))
            }}
        >
            <StandardButton isSmall className="button">
                +
            </StandardButton>
        </div>
    )
}

export default ContentAdder
