import React from "react"
import { useTranslation } from "react-i18next"
import "./NotFoundPage.scss"
import PageWrapper from "./../components/page/PageWrapper"

interface INotFoundPageProps {}

const NotFoundPage = ({}: INotFoundPageProps) => {
    const { t } = useTranslation()
    return (
        <PageWrapper>
            <div id="not-found">
                <div id="not-found-container">
                    <div id="not-found-header" className="text-center">
                        <h1>{t("notfound.title")}</h1>
                    </div>
                    <div id="not-found-text" className="text-center">
                        <p>{t("notfound.text")}</p>
                    </div>
                    <div id="cone-1" className="img-container picture">
                        <img src={require("../images/icons/cone.png")} />
                    </div>
                    <div id="cone-2" className="img-container picture">
                        <img src={require("../images/icons/cone.png")} />
                    </div>
                    <div id="easel" className="img-container picture">
                        <img src={require("../images/icons/easel.png")} />
                    </div>
                </div>
            </div>
        </PageWrapper>
    )
}

export default NotFoundPage
