import React, { useEffect, useRef } from "react"
import "./ApiRouteGroup.scss"
import { IRouteGroup } from "../../services/apiGroupService"
import ApiRoute from "./ApiRoute"
import { getApiRouteKey } from "../../services/apiRouteService"
import ApiRouteName from "./ApiRouteName"
import SwiipeBox from "../box/SwiipeBox"
import { useSelector } from "react-redux"
import { apiReducerActions, apiSelectors } from "../../store/reducers/apiReducer"
import useReduxDispatch from "../../store/useReduxDispatch"

interface IApiRouteGroupProps {
    group: IRouteGroup
    isFirst: boolean
    onPotentialFocus: (percent: number, group: string, route?: string) => void
}

const ApiRouteGroup = ({ group, onPotentialFocus, isFirst }: IApiRouteGroupProps) => {
    const selection = useSelector(apiSelectors.getApiSelection)
    const ref = useRef<HTMLDivElement>(null)
    const info = group.info
    const dispatch = useReduxDispatch()

    useEffect(() => {
        if (selection?.selectedGroup === group.id && !selection?.selectedRoute && selection.selectedBy !== "scrolling") {
            const scrollTop = document.documentElement.scrollTop as number
            const rect = ref.current?.getBoundingClientRect()
            document.documentElement.scrollTo({
                top: scrollTop + (rect?.top ?? 0) - 50,
                behavior: "smooth",
            })
        }
    }, [selection])

    useEffect(() => {
        if (isFirst && (document.documentElement.scrollTop as number) === 0) {
            onPotentialFocus(100, group.id)
        }
    }, [])

    useEffect(() => {
        const onScroll = (e: any) => {
            const rect = ref.current?.getBoundingClientRect()
            const scrollTop = e.target.documentElement.scrollTop as number
            const windowHeight = e.target.documentElement.clientHeight
            const areaStart = windowHeight * 0.45
            const areaEnd = windowHeight * 0.55

            if (scrollTop === 0 && isFirst) {
                onPotentialFocus(100, group.id)
                return
            }

            if (rect && rect.top < areaEnd && rect.bottom > areaStart) {
                // Some percentage
                const topInner = rect.top < areaStart ? areaStart : rect.top
                const bottomInner = rect.bottom > areaEnd ? areaEnd : rect.bottom
                const percent = (bottomInner - topInner) / areaEnd
                onPotentialFocus(percent, group.id)
            }
        }
        window.addEventListener("scroll", onScroll)
        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    return (
        <div className="api-route-group-comp">
            <div ref={ref} className="column-2">
                <div className="intro">
                    <div className="title">{info?.title ?? "Other"}</div>
                    <div className="description">{info?.description ?? <></>}</div>
                </div>
                {info?.descriptionRight && (
                    <div className="description-right">
                        <div className="title"></div>
                        <div>{info?.descriptionRight}</div>
                    </div>
                )}
                {group.routeWithDocList && (
                    <div className="routes-info">
                        <SwiipeBox title="Endpoints">
                            <div className="endpoints">
                                {group.routeWithDocList.map((item) => {
                                    return (
                                        <div
                                            className="route-name"
                                            key={getApiRouteKey(item.route)}
                                            onClick={() => {
                                                dispatch(
                                                    apiReducerActions.setApiSelection({
                                                        selectedBy: "link",
                                                        selectedGroup: group.id,
                                                        selectedRoute: getApiRouteKey(item.route),
                                                    })
                                                )
                                            }}
                                        >
                                            <ApiRouteName alignRows route={item.route} />
                                        </div>
                                    )
                                })}
                            </div>
                        </SwiipeBox>
                    </div>
                )}
            </div>
            {group.routeWithDocList && (
                <div className="routes">
                    {group.routeWithDocList.map((r) => {
                        return (
                            <ApiRoute
                                key={getApiRouteKey(r.route)}
                                route={r.route}
                                doc={r.docMethod}
                                group={group}
                                onPotentialFocus={onPotentialFocus}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default ApiRouteGroup
