import React, { useEffect } from "react"
import PageContainer from "../../components/page/PageContainer"
import PageWrapper from "../../components/page/PageWrapper"
import useReduxDispatch from "../../store/useReduxDispatch"
import { fetchApiDocs } from "../../store/thunks/apiThunks"
import ApiMenu from "../../components/api/ApiMenu"
import ApiContent from "../../components/api/ApiContent"
import "./ApiHomePage.scss"

interface IApiHomePageProps {}

const ApiHomePage = ({}: IApiHomePageProps) => {
    const dispatch = useReduxDispatch()

    useEffect(() => {
        dispatch(fetchApiDocs())
    }, [])

    return (
        <PageWrapper fullWidth noPadding>
            <div className="api-home-page">
                <div className="menu">
                    <ApiMenu />
                </div>
                <div className="content">
                    <ApiContent />
                </div>
            </div>
        </PageWrapper>
    )
}

export default ApiHomePage
