import React from "react"
import { IModalBase, IModalControlProps } from "./common/modalTypes"
import { useTranslation } from "react-i18next"
import BaseModal from "./common/BaseModal"
import ModalFooter from "./common/ModalFooter"
import { Row, ModalBody } from "reactstrap"

export interface IModalLogOut extends IModalBase {
    type: "logOut"
}

const ModalLogOut = ({ callback, ...otherProps }: IModalLogOut & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal {...otherProps} callback={callback} centered>
            <ModalBody>
                <div className="page-icon-container d-flex justify-content-center mt-3 mb-3">
                    <span className="icon icon-logout page-icon"></span>
                </div>
                <Row className="text-center">
                    <div className="col">
                        <h3> {t("logout.text")} </h3>
                    </div>
                </Row>
            </ModalBody>
            <ModalFooter
                wideButtons
                leftButton={{ onClick: () => callback("accepted"), text: t("logout.yes") }}
                rightButton={{ onClick: () => callback("declined"), text: t("logout.no"), isInvertedColor: true }}
            />
        </BaseModal>
    )
}

export default ModalLogOut
