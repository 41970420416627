import React from "react"
import { useSelector } from "react-redux"
import { modalSelectors, modalReducerActions } from "./../../../store/reducers/modalReducer"
import SingleModal from "./SingleModal"

interface IModalRendererProps {}

const ModalRenderer = ({}: IModalRendererProps) => {
    const modals = useSelector(modalSelectors.modals)

    return (
        <div>
            {modals.map((modal, index) => {
                return <SingleModal key={modal.type + index} modal={modal} />
            })}
        </div>
    )
}

export default ModalRenderer
