import React from "react"
import "./UserRelationSearchBox.scss"
import { Input } from "reactstrap"
import { useTranslation } from "react-i18next"

interface IUserRelationSearchBoxProps {
    currentValue: string
    onValueChange: (val: string) => void
}

const UserRelationSearchBox = ({ currentValue, onValueChange }: IUserRelationSearchBoxProps) => {
    const { t } = useTranslation()
    return (
        <div className="swiipe-user-relation-search-box px-md-5">
            <Input
                value={currentValue}
                onChange={(e) => onValueChange(e.target.value)}
                placeholder={t("switchaccountspage.searchfield")}
                className="pl-md-4"
            />
        </div>
    )
}

export default UserRelationSearchBox
