import React, { useEffect, useState, useRef } from "react"
import { useShowLoggedInMenus } from "../../services/authService"
import { Link } from "react-navi"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import { useSelector } from "react-redux"
import { configurationSelectors } from "../../store/reducers/configurationReducer"
import { StoreState } from "../../store/StoreState"

const useShowHideFooter = () => {
    const [showFooter, setShowFooter] = useState(false)
    const showFooterRef = useRef<boolean>()
    showFooterRef.current = showFooter

    const footerHandler = () => {
        if (window.innerHeight + window.scrollY + 5 >= document.body.offsetHeight) {
            if (!showFooterRef.current) {
                setShowFooter(true)
            }
        } else {
            if (showFooterRef.current) {
                setShowFooter(false)
            }
        }
    }

    useEffect(() => {
        const touchEvent = "ontouchstart" in window ? "touchstart" : "click"
        window.addEventListener(touchEvent, footerHandler)
        window.onscroll = footerHandler
    }, [])

    return showFooter
}

const PageFooter = ({}) => {
    const showLoggedInMenus = useShowLoggedInMenus()
    const { t } = useTranslation()
    const showFooter = useShowHideFooter()
    const portalEndpoint = useSelector((state: StoreState) => configurationSelectors.endpointConfig(state, "Portal"))

    return (
        <>
            <div className="pseudo-footer"></div>
            <footer className={cn("footer", { show: showFooter })}>
                <ul className="nav">
                    {showLoggedInMenus && <li className="col-sm-4 order-2 order-sm-1"></li>}
                    {!showLoggedInMenus && portalEndpoint && (
                        <li className="col-sm-4 order-2 order-sm-1">
                            <Link href={portalEndpoint + "/contact_us"} exact>
                                {t("contactform.title")}
                            </Link>
                        </li>
                    )}
                    <li className="col-sm-4 order-3 order-sm-2">
                        <p>
                            {t("footer.text")}
                            <br />
                            {t("footer.text2")}
                        </p>
                    </li>
                    <li className="col-sm-4 order-1 order-sm-3">
                        <a href="//www.swiipe.com" target="_blank" rel="noopener noreferrer" className="social-link-footer">
                            <span className="icon icon-footer-social icon-website"></span>
                        </a>
                        <a
                            href="//www.linkedin.com/company/swiipe"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-link-footer"
                        >
                            <span className="icon icon-footer-social icon-linkedin"></span>
                        </a>
                        <a
                            href="https://www.facebook.com/Swiipe-325067421464696/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-link-footer"
                        >
                            <span className="icon icon-footer-social icon-facebook"></span>
                        </a>
                    </li>
                </ul>
            </footer>
        </>
    )
}

export default PageFooter
