export function getDistinct<T>(arr: T[], selector?: (item: T) => any) {
    const values: T[] = []
    const results: T[] = []
    arr.forEach((elem) => {
        const val = selector ? selector(elem) : elem
        if (values.indexOf(val) < 0) {
            values.push(val)
            results.push(elem)
        }
    })
    return results
}

export function flattenArray<T>(arrayOfArrays: T[][]): T[] {
    return ([] as T[]).concat(...arrayOfArrays)
}

export function hasValue<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && typeof value !== "undefined"
}

export function sortAscending<T>(arr: T[], selector: (item: T) => any) {
    return arr.sort((i1, i2) => {
        const r1 = selector(i1)
        const r2 = selector(i2)
        if (r1 === r2) {
            return 0
        }
        return r1 < r2 ? -1 : 1
    })
}

export function sortDescending<T>(arr: T[], selector: (item: T) => any) {
    return arr.sort((i1, i2) => {
        const r1 = selector(i1)
        const r2 = selector(i2)
        if (r1 === r2) {
            return 0
        }
        return r1 < r2 ? 1 : -1
    })
}

export function toDictSelectValue<T, U>(
    arr: T[],
    keySelector: (item: T) => string,
    valueSelector: (item: T) => U
): { [key: string]: U } {
    if (!arr || arr.length === 0) {
        return {}
    }
    return arr.reduce((dict, item) => {
        const key = keySelector(item)
        dict[key] = valueSelector(item)
        return dict
    }, {})
}

export function toDict<T>(arr: T[], keySelector: (item: T) => string): { [key: string]: T } {
    if (!arr || arr.length === 0) {
        return {}
    }
    return arr.reduce((dict, item) => {
        const key = keySelector(item)
        dict[key] = item
        return dict
    }, {})
}
