import React from "react"
import cn from "classnames"
import "./PageWrapper.scss"

interface IPageWrapperProps {
    children: React.ReactNode
    fullWidth?: boolean
    mediumWidth?: boolean
    noPadding?: boolean
}

const PageWrapper = ({ fullWidth, mediumWidth, children, noPadding }: IPageWrapperProps) => {
    return (
        <div
            id="main-container"
            className={cn(fullWidth && "full-width", mediumWidth && "medium-width", noPadding && "no-padding")}
        >
            {children}
        </div>
    )
}

export default PageWrapper
