import { configurationSelectors } from "./../reducers/configurationReducer"
import { endpoints } from "./../../data/endpoints"
import { apiReducerActions } from "./../reducers/apiReducer"
import { requestThunk } from "./serverThunks"
import { ThunkAction } from "redux-thunk"
import { StoreState } from "../StoreState"
import { AnyAction } from "redux"
import { cleanApiDoc, replaceSelectorsWithMemoryReferences } from "../../services/apiDocServices"
import { IApiDoc, IApiDocRoute, IApiDocsDataResponse } from "../../type/api/apiDocTypes"
import { createHash } from "crypto"
import { createHashFromString } from "../../util/stringUtil"

interface ISavedApiDocData {
    routes: IApiDocRoute[]
    docs: IApiDoc[]
}

interface ISavedApiDocResponse {
    apiDocJson: string
    version: string
    build: number
    modified: string
}

export const fetchApiDocs = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
    const env = configurationSelectors.environment(getState())
    let docs: IApiDoc[] = []
    let routes: IApiDocRoute[] = []
    let modified = new Date()

    if (env === "Development") {
        const resp = await dispatch(requestThunk<IApiDocsDataResponse>(endpoints.MainApi.getApiSpec))
        docs = Object.keys(resp.docsJson).map((docEndpoint) =>
            cleanApiDoc(
                JSON.parse(resp.docsJson[docEndpoint]),
                resp.routes.filter((r) => r.docsJsonEndpoint === docEndpoint),
                docEndpoint
            )
        )
        routes = resp.routes
    } else {
        const savedDoc = await dispatch(requestThunk<ISavedApiDocResponse>(endpoints.Content.getApiDoc))
        const data: ISavedApiDocData = savedDoc.apiDocJson
            ? (JSON.parse(savedDoc.apiDocJson) as ISavedApiDocData)
            : { routes: [], docs: [] }
        docs = data.docs
        routes = data.routes
        modified = new Date(savedDoc.modified)
    }

    const docsWithResolvedSelectors = docs.map((d) => replaceSelectorsWithMemoryReferences(d))

    dispatch(apiReducerActions.setApiDocs(docsWithResolvedSelectors, routes, modified))
}

export const ensureNewestApiDocs = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
    const resp = await dispatch(requestThunk<IApiDocsDataResponse>(endpoints.MainApi.getApiSpec))
    const cleanedDocs = Object.keys(resp.docsJson).map((docEndpoint) =>
        cleanApiDoc(
            JSON.parse(resp.docsJson[docEndpoint]),
            resp.routes.filter((r) => r.docsJsonEndpoint === docEndpoint),
            docEndpoint
        )
    )

    const data: ISavedApiDocData = {
        routes: resp.routes,
        docs: cleanedDocs,
    }
    const dataStr = JSON.stringify(data)

    const formData = new FormData()

    formData.append("apiDocJson", new File([dataStr], "apiDoc.json"))
    formData.append("version", resp.version)
    formData.append("hash", createHashFromString(dataStr))

    const result = await dispatch(
        requestThunk<{ alreadyUpToDate: boolean }>(endpoints.Content.addApiDoc, {
            data: formData,
        })
    )

    if (!result.alreadyUpToDate) {
        await dispatch(fetchApiDocs())
    }

    alert(result.alreadyUpToDate ? "Already up to date" : "Updated successfully")
}
