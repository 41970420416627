import React, { useState } from "react"
import PageContainer from "../../components/page/PageContainer"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import PageWrapper from "../../components/page/PageWrapper"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import UserRelationInfo from "./../../components/user/UserRelationInfo"
import UserRelationSearchBox from "./../../components/user/UserRelationSearchBox"

interface IManageUserRelationsPageProps {}

const ManageUserRelationsPage = ({}: IManageUserRelationsPageProps) => {
    const { t } = useTranslation()
    const [searchValue, setSearchValue] = useState("")

    const userRelations = useSelector(userRelationSelectors.userRelationsFlattened)!
    const searchValueLowerCase = searchValue.toLowerCase()
    const userRelationsToShow = !searchValue
        ? userRelations
        : userRelations.filter((rel) => !!rel.name && rel.name.toLowerCase().indexOf(searchValueLowerCase) >= 0)

    const partners = userRelations.filter((ur) => ur.relationType === "Partner")

    return (
        <PageWrapper>
            <PageContainer transparent title={t("organizationUsers.manageuserRelations")}>
                <UserRelationSearchBox
                    currentValue={searchValue}
                    onValueChange={(val) => {
                        setSearchValue(val)
                    }}
                />
                {userRelationsToShow.map((rel) => {
                    return (
                        <UserRelationInfo
                            key={rel.id + (rel.parentId || "")}
                            userRelation={rel}
                            parentUserRelation={rel.parentId ? partners.find((p) => p.id === rel.parentId) : undefined}
                        />
                    )
                })}
            </PageContainer>
        </PageWrapper>
    )
}

export default ManageUserRelationsPage
