import React from "react"
import { Button, ModalFooter as ModFooter, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import StandardButton from "./../../buttons/StandardButton"

interface ModalFooterButton {
    onClick: () => void
    text: string
    isInvertedColor?: boolean
    isDelete?: boolean
}

interface IModalFooterProps {
    leftButton?: ModalFooterButton
    rightButton?: ModalFooterButton
    wideButtons?: boolean
}

const ModalFooter = ({ leftButton, rightButton, wideButtons }: IModalFooterProps) => {
    return (
        <ModFooter>
            <div slot="modal-footer" className="w-100">
                <Row className="justify-content-between">
                    {leftButton && (
                        <div className={cn(wideButtons ? "col-md-5 col-6" : "col-auto")}>
                            <StandardButton
                                inverted={leftButton.isInvertedColor}
                                isDelete={leftButton.isDelete}
                                onClick={leftButton.onClick}
                                title={leftButton.text}
                            />
                        </div>
                    )}
                    <div />
                    {rightButton && (
                        <div className={cn(wideButtons ? "offset-md-2 col-md-5 col-6" : "col-auto")}>
                            <StandardButton
                                inverted={rightButton.isInvertedColor}
                                isDelete={rightButton.isDelete}
                                onClick={rightButton.onClick}
                                title={rightButton.text}
                            />
                        </div>
                    )}
                </Row>
            </div>
        </ModFooter>
    )
}

export default ModalFooter
