import { IUserRelation, IUserRelationFlattened } from "../type/IUserRelation"
import { TUserRelation } from "../type/TUserRelation"
import { TUserRole } from "../type/TUserRole"
import { useState } from "react"
import { useSelector } from "react-redux"
import { navigationService } from "./navigationService"
import { userRelationSelectors } from "../store/reducers/userRelationReducer"
import { toUpperAndTrim } from "./../util/stringUtil"

export const flattenUserRelations = (userRelations: IUserRelation[]): IUserRelationFlattened[] => {
    let flattened: IUserRelationFlattened[] = []
    sortUserRelatoinsAlfabetical(userRelations).forEach((ul) => {
        flattened = [...flattened, ...flattenUserRelation(ul)]
    })
    return flattened
}

const flattenUserRelation = (userRelation: IUserRelation): IUserRelationFlattened[] => {
    const flattenedSubRelations: IUserRelationFlattened[] = (userRelation.subRelations || []).map((sr) => ({
        id: sr.id,
        name: sr.name,
        relationType: sr.relationType,
        parentId: userRelation.id,
        userRole: sr.userRole,
    }))

    return [
        {
            id: userRelation.id,
            name: userRelation.name,
            relationType: userRelation.relationType,
            userRole: userRelation.userRole,
        },
        ...sortFlattenedSubRelatoinsAlfabetical(flattenedSubRelations),
    ]
}

function sortUserRelatoinsAlfabetical(userRelations: IUserRelation[]): IUserRelation[] {
    const result = userRelations.sort((a, b) => {
        if (a.relationType === "User") {
            return -1
        } else if (b.relationType === "User") {
            return 1
        }

        if (toUpperAndTrim(a.name) < toUpperAndTrim(b.name)) {
            if (a.relationType === "Partner" && b.relationType === "Merchant") {
                return 1
            }

            return -1
        }

        if (toUpperAndTrim(a.name) > toUpperAndTrim(b.name)) {
            if (a.relationType === "Merchant" && b.relationType === "Partner") {
                return -1
            }

            return 1
        }

        return 0
    })

    return result
}

function sortFlattenedSubRelatoinsAlfabetical(flattenedSubRelations: IUserRelationFlattened[]): IUserRelationFlattened[] {
    const result = flattenedSubRelations.sort((a, b) =>
        toUpperAndTrim(a.name) < toUpperAndTrim(b.name) ? -1 : toUpperAndTrim(a.name) > toUpperAndTrim(b.name) ? 1 : 0
    )

    return result
}

export interface IRequiredUserRelation {
    requiredType?: TUserRelation
    requiredRole?: TUserRole
}

export const useRequiredUserRelations = (requirements: IRequiredUserRelation[]) => {
    const [checked, setChecked] = useState(false)
    const currenUserRelation = useSelector(userRelationSelectors.currentUserRelation)

    if (checked || !currenUserRelation) {
        return
    }

    const matchFound = requirements.find(
        (req) =>
            (!req.requiredRole || req.requiredRole <= currenUserRelation.userRole) &&
            (!req.requiredType || req.requiredType === currenUserRelation.relationType)
    )

    if (matchFound) {
        return
    }
    setChecked(true)
    navigationService.navigate(getRootUrlForRelation(currenUserRelation))
}

export const getCurrentUserRelationFromLocalStorage = (
    userRelations: IUserRelationFlattened[]
): IUserRelationFlattened | undefined => {
    const storedStr = localStorage.getItem("currentUserRelation")
    const relationFromLocalStorage = (storedStr ? JSON.parse(storedStr) : undefined) as IUserRelationFlattened | undefined
    if (!relationFromLocalStorage) {
        return
    }
    const rel = userRelations.find(
        (ur) => ur.id === relationFromLocalStorage.id && ur.parentId === relationFromLocalStorage.parentId
    )
    return rel
}

export const setUserRelationToLocalStorage = (userRelationFlattened: IUserRelationFlattened) => {
    localStorage.setItem("currentUserRelation", JSON.stringify(userRelationFlattened))
}

export const getLastUsedRelationsFromLocalStorage = (
    currentUserId: string,
    userRelationsFlattened: IUserRelationFlattened[]
): IUserRelationFlattened[] | undefined => {
    const storedStr = localStorage.getItem("lastUsedRelations")
    const idsFromLocalStorage = (storedStr ? JSON.parse(storedStr) : undefined) as { [userId: string]: string[] } | undefined

    if (!idsFromLocalStorage || !idsFromLocalStorage[currentUserId]) {
        return
    }

    return filterAndSortUserRelationsForSwitchUserMenu(userRelationsFlattened, idsFromLocalStorage[currentUserId])
}

export const filterAndSortUserRelationsForSwitchUserMenu = (
    userRelationsFlattenedActive: IUserRelationFlattened[],
    lastVisitedAccounts: string[]
) => {
    const uniqueRelations = userRelationsFlattenedActive.filter(
        (rel, index, self) => self.findIndex((r) => r.id === rel.id) === index
    )

    const filteredAndSorted = uniqueRelations
        .filter((rel) => lastVisitedAccounts.includes(rel.id))
        .sort((a, b) => (lastVisitedAccounts.indexOf(a.id) > lastVisitedAccounts.indexOf(b.id) ? 1 : -1))
        .sort((a, b) => (a.relationType === "User" ? -1 : b.relationType === "User" ? 1 : 0))

    return filteredAndSorted.length === 5
        ? filteredAndSorted
        : [...filteredAndSorted, ...uniqueRelations]
              .filter((rel, index, self) => self.findIndex((r) => r.id === rel.id) === index)
              .slice(0, 5)
}

export const setLastUsedRelationsToLocalStorage = (currentUserId: string, ids: string[]) => {
    const storedStr = localStorage.getItem("lastUsedRelations")
    const idsFromLocalStorage = (storedStr ? JSON.parse(storedStr) : undefined) as { [userId: string]: string[] } | undefined

    if (!idsFromLocalStorage) {
        localStorage.setItem("lastUsedRelations", JSON.stringify({ [currentUserId]: ids }))
        return
    }

    idsFromLocalStorage[currentUserId] = ids
    localStorage.setItem("lastUsedRelations", JSON.stringify(idsFromLocalStorage))
}

export const getRootUrlForRelation = (userRelationFlattened: IUserRelationFlattened) => {
    switch (userRelationFlattened.relationType) {
        case "Merchant":
            return "/api_key"
        case "User":
            return "/personalinfo"
        case "Partner":
            return "/partnermerchantrelations"
        case "CallCenter":
            return "/"
        default:
            return "/"
    }
}

export const getTextIdForRole = (role: number) => {
    if (role >= TUserRole.Owner) {
        return "roles.owner"
    }
    if (role >= TUserRole.Admin) {
        return "roles.admin"
    }
    return "roles.member"
}

export const getIconClassForRelation = (userRelation: IUserRelationFlattened) => {
    switch (userRelation.relationType) {
        case "User":
            return "icon-nav-user"
        case "Merchant":
            return "icon-roleswitch-merchant"
        case "Partner":
            return "icon-roleswitch-partner"
        case "CallCenter":
            if (userRelation.userRole >= TUserRole.Admin) {
                return "icon-nav-manager"
            }
            return "icon-icon-agent"
        default:
            return ""
    }
}
