import { createHash } from "crypto"

export function toUpperAndTrim(text: string): string {
    if (!text) {
        return text
    }

    return text.toUpperCase().trim()
}

export function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

export function createHashFromString(str: string) {
    return createHash("md5").update(str).digest("hex")
}
