import React, { useState } from "react"
import ModalError, { IModalError } from "../ModalError"
import ModalLogOut, { IModalLogOut } from "../ModalLogOut"
import { IModalCallback, TModalResponseType } from "./modalTypes"
import { modalReducerActions } from "../../../store/reducers/modalReducer"
import ModalLoggedOut, { IModalLoggedOut } from "../ModalLoggedOut"
import useReduxDispatch from "../../../store/useReduxDispatch"

export type TAllModals = IModalError | IModalLogOut | IModalLoggedOut

export type TAllModalsWithCallback = TAllModals & IModalCallback

interface ISingleModalProps {
    modal: TAllModalsWithCallback
}

const SingleModal = ({ modal }: ISingleModalProps) => {
    const { callback, ...modalWithoutCallback } = modal
    const [modalResponse, setModalResponse] = useState<[TModalResponseType, any | undefined]>()
    const dispatch = useReduxDispatch()

    const controlProps = {
        isOpen: !modalResponse,
        callback: (res: TModalResponseType, data?: any) => {
            setModalResponse([res, data])
        },
        onClosed: () => {
            dispatch(modalReducerActions.removeModal(modal))
            callback(modalResponse ? modalResponse[0] : "ignored", modalResponse ? modalResponse[1] : undefined)
        },
    }

    switch (modalWithoutCallback.type) {
        case "error":
            return <ModalError {...modalWithoutCallback} {...controlProps} />
        case "logOut":
            return <ModalLogOut {...modalWithoutCallback} {...controlProps} />
        case "modalLoggedOut":
            return <ModalLoggedOut {...modalWithoutCallback} {...controlProps} />
        default:
            return null
    }
}

export default SingleModal
