export function tryToRedirect(path: string): boolean {
    const redirectMapping = redirectMappings.find(
        (m) =>
            (m.from === path || m.from === path + "/") && (!m.hostEndswith || window.location.hostname.endsWith(m.hostEndswith))
    )
    if (redirectMapping) {
        window.location.replace(redirectMapping.to)
    }
    return false
}

const redirectMappings: { from: string; to: string; hostEndswith?: string }[] = [
    { from: "/", to: "/api", hostEndswith: "developer.swiipe.com" },
    { from: "/", to: "https://swiipe-support.zendesk.com/hc/da", hostEndswith: "docs.swiipe.com" },
    { from: "/docs", to: "https://swiipe-support.zendesk.com/hc/da/sections/4420491524625-Guides" },
    { from: "/faqs", to: "https://swiipe-support.zendesk.com/hc/da/sections/4420491525521-FAQ" },
    {
        from: "/docs/woocommerceinstallationsguide",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4571616461073-S%C3%A5dan-installerer-du-Swiipe-Checkout-p%C3%A5-din-WooCommerce-webshop",
    },
    {
        from: "/docs/googleautocomplete",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4571705309713-S%C3%A5dan-aktiverer-du-Google-Autocomplete-p%C3%A5-din-WooCommerce-webshop",
    },
    {
        from: "/docs/activateswiipepayments",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4572954553745-S%C3%A5dan-aktiverer-du-Swiipes-betalingsmetoder",
    },
    {
        from: "/docs/googleapikey",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4572895327761-S%C3%A5dan-f%C3%A5r-du-en-Google-API-n%C3%B8gle",
    },
    {
        from: "/docs/activatedeliverymethodswoocommerce",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573003795345-S%C3%A5dan-aktiverer-du-leveringsmetoderne-i-WooCommerce",
    },
    {
        from: "/docs/guidetilbetalingslinkwoocommerce",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4572993167633-S%C3%A5dan-opretter-du-et-betalingslink-i-WooCommerce",
    },
    {
        from: "/docs/plussellguide",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/5681496730513-Guide-til-Plus-Sell-version-2-0-",
    },
    {
        from: "/docs/plussellwoocommerce",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4572995454993-S%C3%A5dan-aktiverer-du-Swiipe-Plus-Sell-p%C3%A5-din-WooCommerce-webshop",
    },
    {
        from: "/docs/skipthecartchecklist",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573138572689-Checklist-for-Swiipe-skip-the-cart-feature",
    },
    {
        from: "/docs/magento1installationsguide",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573235089553-S%C3%A5dan-installerer-du-Swiipe-Checkout-p%C3%A5-din-Magento-1-9-webshop",
    },
    {
        from: "/docs/magento1funktioner",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573248905489-S%C3%A5dan-aktiverer-du-Swiipes-integrationer-og-funktioner-p%C3%A5-din-Magento-1-9-webshop-moms-skat-kuponkoder-Google-Autocomplete-tilpassede-sektioner-",
    },
    {
        from: "/docs/guidetilbetalingslinkmagento1",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573280431761-S%C3%A5dan-opretter-du-et-betalingslink-i-Magento-1-9",
    },
    {
        from: "/docs/plussellmagento1",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573306458385-S%C3%A5dan-aktiverer-du-Swiipe-Plus-Sell-p%C3%A5-din-Magento-1-9-webshop",
    },
    {
        from: "/docs/magento2installationsguide",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573363627025-S%C3%A5dan-aktiverer-du-Swiipe-Checkout-p%C3%A5-din-Magento-2-webshop",
    },
    {
        from: "/docs/plussellmagento2",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573382487569-S%C3%A5dan-aktiverer-du-Swiipe-Plus-Sell-p%C3%A5-din-Magento-2-webshop",
    },
    {
        from: "/docs/kortbetalingerguide",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573397357201-Swiipe-Kortbetalinger-guide",
    },
    {
        from: "/docs/kortbetalingerguideudenclearhaus",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573406933905-Swiipe-Kortbetalinger-Ikke-kunde-hos-Clearhaus",
    },
    {
        from: "/docs/mobilepayonlineguide",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573492478993-MobilePay-onboarding-guide",
    },
    {
        from: "/docs/onboardingdankort",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573536993297-Dankort-onboarding-guide",
    },
    {
        from: "/docs/plussellhelloretail",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573544543505-S%C3%A5dan-ops%C3%A6tter-du-Swiipe-Plus-Sell-med-Hello-Retail-",
    },
    {
        from: "/docs/plussellclerk",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573580605713-S%C3%A5dan-ops%C3%A6tter-du-Swiipe-Plus-Sell-med-Clerk",
    },
    {
        from: "/docs/plussellnativesetup",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573632073617-S%C3%A5dan-ops%C3%A6tter-du-Swiipe-Plus-Sell-med-standard-funktioner-i-dit-kontrolpanel",
    },
    {
        from: "/docs/bookkeepingtools",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573643706257-Swiipe-Bookkeeping-tool-guide-SKAL-RETTES-",
    },
    {
        from: "/docs/integrationtilviabill",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573664692241-Integration-til-Viabill",
    },
    {
        from: "/docs/integrationtilstorebuddy",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573674999441-Integration-til-Storebuddy-herunder-Billy-Dinero-E-conomic-",
    },
    {
        from: "/docs/integrationtilforbrugsforeningen",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573731479697-Integration-til-Forbrugsforeningen",
    },
    {
        from: "/docs/integrationtilshipmondo",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573748232209-Integration-til-Shipmondo",
    },
    {
        from: "/docs/integrationtillagersystem",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573751789073-Integration-til-Lagersystem",
    },
    {
        from: "/docs/partneronboardingguide",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573798924561-S%C3%A5dan-bliver-du-Swiipe-partner",
    },
    {
        from: "/docs/partnerportalguide",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573764209041-S%C3%A5dan-bruger-du-Swiipe-Partner-Portalen",
    },
    {
        from: "/docs/updateswiipeplugin",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573864073489-S%C3%A5dan-opdaterer-du-dit-Swiipe-Plugin",
    },

    {
        from: "/faq/suspectedfraud",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4420475058705-Hvad-g%C3%B8r-man-ved-mistanke-om-svindel-",
    },
    {
        from: "/faq/howtocancelswiipe",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4420491524753-Hvordan-annullerer-man-sin-Swiipe-l%C3%B8sning-",
    },
    {
        from: "/faq/icantseedataintheportal",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4420491525649-Hvordan-skifter-man-mellem-sin-e-commerce-og-personlige-konto-i-Swiipe-Portalen-",
    },
    {
        from: "/faq/howtoaddmobilepaylogo",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4420491525905-Hvordan-tilf%C3%B8jer-man-Mobilepay-logo-",
    },
    {
        from: "/faq/changeinvoicemailportal",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4420491526289-Hvordan-%C3%A6ndrer-man-sin-faktureringsmail-",
    },
    {
        from: "/faq/automaticinvoicepayment",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4420491526545-Hvordan-s%C3%A6tter-man-automatisk-betaling-op-til-faktura-",
    },
    {
        from: "/faq/addusersinswiipeportal",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4447774591889-Hvordan-tilf%C3%B8jer-man-brugere-i-Swiipe-Portalen-",
    },
    {
        from: "/faq/partialcapture",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4570840179985-Hvordan-fungerer-delvis-h%C3%A6vning-",
    },
    {
        from: "/faq/partialrefund",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4570989972369-Hvordan-fungerer-delvis-refundering-",
    },
    {
        from: "/faq/changepaymentsorder",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4571052943505-Hvordan-%C3%A6ndrer-man-r%C3%A6kkef%C3%B8lgen-af-hvilke-betalingsmetoder-der-bliver-vist-i-checkoutet-",
    },
    {
        from: "/faq/howtomovefields",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4571240382481-Hvordan-kan-man-flytte-p%C3%A5-felterne-i-checkoutet-",
    },
    {
        from: "/faq/howlongismoneyreserved",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4571245102353-Hvor-l%C3%A6nge-st%C3%A5r-penge-reserveret-i-banken-",
    },
    {
        from: "/faq/updatebankorcvrinformation",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4571282147217-Hvordan-%C3%A6ndrer-man-bank-og-eller-virksomheds-oplysninger-",
    },
    {
        from: "/faq/testafteronboarding",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4571317212689-Hvordan-tester-man-efter-en-onboarding-med-Swiipe-",
    },

    {
        from: "https://docs.swiipe.com/docs/releasenoteswoocommerce",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573892097297-Swiipe-udgivelsesnoter-til-WooCommerce",
    },
    {
        from: "https://docs.swiipe.com/docs/releasenotesmagento1",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573901454737-Swiipe-udgivelsesnoter-til-Magento-1-9",
    },
    {
        from: "https://docs.swiipe.com/docs/releasenotesmagento2",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573930589073-Swiipe-udgivelsesnoter-til-Magento-2",
    },
    {
        from: "https://docs.swiipe.com/docs/releasenotesportal",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573933705873-Swiipe-udgivelsesnoter-til-Swiipe-Portalen",
    },
    {
        from: "https://docs.swiipe.com/docs/releasenotessandbox",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573964043025-Swiipe-udgivelsesnoter-til-Sandbox",
    },
    {
        from: "https://docs.swiipe.com/docs/releasenotesapi",
        to: "https://swiipe-support.zendesk.com/hc/da/articles/4573973034129-Swiipe-udgivelsesnoter-til-API",
    },
]
