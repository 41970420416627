import React, { useState } from "react"
import { IUserRelationFlattened } from "../../type/IUserRelation"
import { getIconClassForRelation, getRootUrlForRelation } from "../../services/userRelationService"
import { navigationService } from "../../services/navigationService"
import useReduxDispatch from "../../store/useReduxDispatch"
import { switchUserThunk } from "../../store/thunks/startupThunks"
import cn from "classnames"
import "./UserRelationInfo.scss"
import { useTranslation } from "react-i18next"
import StandardButtonWithSpinner from "../buttons/StandardButtonWithSpinner"

interface IUserRelationInfoProps {
    userRelation: IUserRelationFlattened
    parentUserRelation?: IUserRelationFlattened
}

const UserRelationInfo = ({ userRelation, parentUserRelation }: IUserRelationInfoProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const [isBtnActive, setBtnActive] = useState(false)

    return (
        <div className="swiipe-user-relation-info px-md-5">
            <span className={cn("icon", getIconClassForRelation(userRelation))}></span>
            <div className="userrelation ml-4 mr-1" onClick={() => setBtnActive(!isBtnActive)}>
                <div className="name">{userRelation.name}</div>
                {parentUserRelation && <div className="parentname">{parentUserRelation.name}</div>}
            </div>
            <div className={cn("div-fullwidth")}>
                <div className="action-box">
                    <StandardButtonWithSpinner
                        dark
                        className={cn("btn-switch", "action-button", "mt-3", "border-0")}
                        title={t("switchaccountspage.choose")}
                        onClick={async () => {
                            await dispatch(switchUserThunk(userRelation))
                            navigationService.navigate(getRootUrlForRelation(userRelation))
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserRelationInfo
