import React from "react"
import "./ContentEditorActions.scss"
import { contentReducerActions, contentSelectors } from "../../store/reducers/contentReducer"
import { useSelector } from "react-redux"
import useReduxDispatch from "../../store/useReduxDispatch"
import { addOrUpdateGroupContentItemsThunk } from "../../store/thunks/contentThunks"
import StandardButtonWithSpinner from "../buttons/StandardButtonWithSpinner"
import StandardButton from "../buttons/StandardButton"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import DropDownList from "../form/input/DropDownList"
import { getAllLanguages, TLanguage } from "../../type/TLanguage"

interface IContentEditorActionsProps {}

const ContentEditorActions = ({}: IContentEditorActionsProps) => {
    const dispatch = useReduxDispatch()
    const currentGroup = useSelector(contentSelectors.getCurrentGroup)
    const isInEditorMode = useSelector(contentSelectors.getIsInEditorMode)
    const isMasterPartner = useSelector(userRelationSelectors.isMasterPartner)
    const allLanguages = getAllLanguages()
    const currentLanguage = useSelector(contentSelectors.getCurrentEditorLanguage)

    if (!isMasterPartner) {
        return null
    }

    return (
        <div className="content-editor-actions-comp">
            <div className="actions">
                <div className="editor-button">
                    <StandardButton
                        isSmall
                        inverted
                        onClick={async () => {
                            await dispatch(contentReducerActions.setEditorMode(!isInEditorMode))
                        }}
                    >
                        {isInEditorMode ? "Hide editor" : "Show editor"}
                    </StandardButton>
                </div>
                <DropDownList
                    className="language_ddl"
                    value={currentLanguage}
                    options={allLanguages.map((l) => ({ text: l, value: l }))}
                    onChange={(e) => {
                        dispatch(contentReducerActions.setCurrentLanguage(e.currentTarget.value as TLanguage))
                    }}
                />
                {currentGroup && isInEditorMode && (
                    <StandardButtonWithSpinner
                        isSmall
                        inverted
                        className="save-button"
                        onClick={async () => {
                            if (!currentGroup) {
                                return
                            }
                            await dispatch(addOrUpdateGroupContentItemsThunk(currentGroup))
                        }}
                    >
                        Save
                    </StandardButtonWithSpinner>
                )}
            </div>
        </div>
    )
}

export default ContentEditorActions
