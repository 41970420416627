import { endpoints } from "./../../data/endpoints"
import { ThunkAction } from "redux-thunk"
import { StoreState } from "../StoreState"
import { AnyAction } from "redux"
import { requestThunk } from "./serverThunks"
import { addModalThunk } from "./modalThunks"
import { navigationService } from "./../../services/navigationService"

interface ICheckCookieResponse {
    hasApiCookie: boolean
    isLoggedIn: boolean
    expUnixTime?: string
}

const latestExpirationTimeKey = "latestCookieExpiration"

const pushStateListeners: (() => void)[] = []

const pushState = window.history.pushState
window.history.pushState = function (...args) {
    pushState.apply(window.history, args)
    pushStateListeners.forEach((l) => l())
}

export const checkCookieThunk =
    (): ThunkAction<Promise<ICheckCookieResponse>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        const response = await dispatch(requestThunk<ICheckCookieResponse>(endpoints.Self.checkCookie))

        if (window.self !== window.top) {
            // Do not start new cookie handling inside iframe
            return response
        }

        dispatch(handleCookieTimeoutThunk(response))
        return response
    }

const isLatestExpireTime = (cookieExpireTime: number) => {
    const storedExpirationStr = localStorage.getItem(latestExpirationTimeKey)
    const storedExpiration = storedExpirationStr ? parseInt(storedExpirationStr) : undefined
    const isCookieLatest = storedExpiration && cookieExpireTime >= storedExpiration
    return !storedExpiration || isCookieLatest
}

const handleCookieTimeoutThunk =
    (cookieResponse: ICheckCookieResponse): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!cookieResponse.expUnixTime) {
            return
        }

        const cookieValidUntilSeconds = parseInt(cookieResponse.expUnixTime) // unix time is in seconds
        const elapsedTimeInSeconds = Math.floor(Date.now() / 1000) // js time is in milliseconds - divide by 1000 to get seconds
        const timeoutSeconds = Math.max(cookieValidUntilSeconds - elapsedTimeInSeconds, 0)
        const renewBufferSeconds = 120 + Math.floor(Math.random() * 120) // Random time slack to avoid all renewing at the same time
        const renewTimeSeconds = Math.max(timeoutSeconds - renewBufferSeconds, 0)

        // If renew fails the normal logged out modal should trigger else reschedule
        const showModalTimeout = setTimeout(async () => {
            const cookieResponse = await dispatch(requestThunk<ICheckCookieResponse>(endpoints.Self.checkCookie))
            const cookieExpireTime = cookieResponse.expUnixTime ? parseInt(cookieResponse.expUnixTime) : undefined

            if (cookieExpireTime && isLatestExpireTime(cookieExpireTime)) {
                localStorage.setItem(latestExpirationTimeKey, cookieExpireTime.toString())
                dispatch(handleCookieTimeoutThunk(cookieResponse))
                return
            }
            await dispatch(addModalThunk({ type: "modalLoggedOut" }))
        }, timeoutSeconds * 1000)

        if (timeoutSeconds > renewBufferSeconds) {
            // Schedule auto cookie renewal
            // Only renew if the users is active in the session to avoid excessive calls to auth
            let isTheUserThere = false
            const handleNavigation = () => (isTheUserThere = true)
            const handleScroll = () => (isTheUserThere = true)

            pushStateListeners.push(handleNavigation)
            document.addEventListener("scroll", handleScroll)

            setTimeout(async () => {
                pushStateListeners.splice(pushStateListeners.indexOf(handleNavigation), 1)
                document.removeEventListener("scroll", handleScroll)

                const container = document.getElementById("cookie-expiration-extender")
                if (!container || !isTheUserThere) {
                    return
                }

                // Check if already renewed from other tabs
                if (!container || !isTheUserThere || !isLatestExpireTime(cookieValidUntilSeconds)) {
                    // If already renewed from other tabs
                    return
                }
                localStorage.setItem(latestExpirationTimeKey, (cookieValidUntilSeconds + 1800).toString()) // Add 30 min to prevent other threads from trying to renew

                // Insert cookie into the cookie-expiration-extender div
                const iframe = document.createElement("iframe")
                iframe.src = document.location.origin + "?forceauth=1"
                iframe.style["height"] = "0px"
                iframe.style["display"] = "none"
                container.appendChild(iframe)

                // The until we expect the cookie renewal the succeed - so we remove the iframe after that
                const timeToFinishLogin = 20 * 1000
                setTimeout(async () => {
                    container.removeChild(iframe)
                }, timeToFinishLogin)
            }, renewTimeSeconds * 1000)
        }
    }

export const logOutThunk = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
    const response = await dispatch(addModalThunk({ type: "logOut" }))
    if (response.type === "accepted") {
        navigationService.navigate("forgotpassword")
    }
}
