import { IApiEndpoint } from "./../../type/request/IApiEndpoint"
import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"
import { ServerConfiguration } from "../../type/serverConfiguration"
import { TEndpointName } from "../../type/request/IApiEndpoint"

export type ConfigurationState = Readonly<{
    serverConfiguration?: ServerConfiguration
    initialized: boolean
    disableRedirects: boolean
}>
const initialState: ConfigurationState = { serverConfiguration: undefined, initialized: false, disableRedirects: false }

export const configurationReducerActions = {
    setServerConfiguration: (serverConfiguration: ServerConfiguration) =>
        createAction("APP/SET_SERVER_CONFIGURATION", { serverConfiguration }),
    setInitialized: (initialized: boolean) => createAction("APP/SET_INITIALIZED", { initialized }),
    setDisableRedirects: (disableRedirects: boolean) => createAction("APP/SET_DISABLE_REDIRECTS", { disableRedirects }),
}

export type ConfigurationActions = ActionsUnion<typeof configurationReducerActions>

export const configurationReducer = (
    state: ConfigurationState = initialState,
    action: ConfigurationActions
): ConfigurationState => {
    switch (action.type) {
        case "APP/SET_SERVER_CONFIGURATION": {
            return {
                ...state,
                serverConfiguration: action.serverConfiguration,
            }
        }
        case "APP/SET_INITIALIZED": {
            return {
                ...state,
                initialized: action.initialized,
            }
        }
        case "APP/SET_DISABLE_REDIRECTS": {
            return {
                ...state,
                disableRedirects: action.disableRedirects,
            }
        }
    }

    return state
}

export const configurationSelectors = {
    serverConfiguration: (state: StoreState) => state.configuration.serverConfiguration!,
    environment: (state: StoreState) => state.configuration.serverConfiguration?.environment ?? "Production",
    endpointConfig: (state: StoreState, endpointName: TEndpointName): string => {
        const origins = state.configuration.serverConfiguration?.origins
        if (!origins) {
            return ""
        }
        return origins[endpointName]
    },
    endpoint: (state: StoreState, endpoint: IApiEndpoint) => {
        const origins = state.configuration.serverConfiguration?.origins
        if (!origins) {
            return ""
        }
        return origins[endpoint.endpoint] + endpoint.path
    },
    initialized: (state: StoreState) => !!state.configuration.serverConfiguration && state.configuration.initialized,
    isRedirectsDisabled: (state: StoreState) => state.configuration.disableRedirects,
}
