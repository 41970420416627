import React, { useState } from "react"
import { authService, useShowLoggedInMenus } from "./../../services/authService"
import { Collapse } from "reactstrap"
import { NavbarToggler } from "reactstrap"
import { useSelector } from "react-redux"
import { slide as Menu } from "react-burger-menu"
import { layoutSelectors, layoutReducerActions } from "../../store/reducers/layoutReducer"
import useReduxDispatch from "../../store/useReduxDispatch"

interface ISidebarProps {
    children?: React.ReactNode
}

const Sidebar = ({ children }: ISidebarProps) => {
    const isOpen = useSelector(layoutSelectors.navBarOpen)
    const dispatch = useReduxDispatch()
    const showLoggedInMenus = useShowLoggedInMenus()

    return (
        <>
            {showLoggedInMenus && <nav id="sidebar">{children}</nav>}
            <Menu
                burgerButtonClassName="d-none"
                isOpen={isOpen}
                onStateChange={(state) => dispatch(layoutReducerActions.setNavBarOpen(state.isOpen))}
            >
                <nav id="sidebar-mobile">
                    <NavbarToggler
                        onClick={() => dispatch(layoutReducerActions.setNavBarOpen(false))}
                        target="sidebar-collapse"
                        id="close-sidebar"
                    ></NavbarToggler>
                    {children}
                </nav>
            </Menu>
        </>
    )
}

export default Sidebar
