import React from "react"

export interface IStandarText {
    identifier: string
    description: string
}

export const standardTexts: IStandarText[] = [
    {
        identifier: "currencyText",
        description: `<span>We use the ISO 4217 standard the currencies. That is e.g. EUR for euros.</span>`,
    },
]
