import { endpoints } from "./../../data/endpoints"
import { ThunkAction } from "redux-thunk"
import { StoreState } from "../StoreState"
import { AnyAction } from "redux"
import { requestThunk } from "./serverThunks"
import { IUserRelations } from "./../../type/IUserRelations"
import {
    getCurrentUserRelationFromLocalStorage,
    getLastUsedRelationsFromLocalStorage,
    setLastUsedRelationsToLocalStorage,
} from "../../services/userRelationService"
import { userRelationSelectors, userRelationReducerActions } from "../reducers/userRelationReducer"
import { IRelationsForOrganization } from "../../type/IRelationsForOrganization"
import { IUserRelationFlattened } from "../../type/IUserRelation"
import { getQueryValueFromCurrentUrl, removeQueryValueFromCurrentUrl } from "../../util/urlUtil"
import { switchUserThunk } from "./../../store/thunks/startupThunks"

export const ensureUserRelationsThunk =
    (force?: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentRelation = userRelationSelectors.currentUserRelation(getState())
        if (currentRelation && !force) {
            // Already set up
            return
        }

        const userRelations = await dispatch(requestThunk<IUserRelations>(endpoints.Core.getUserRelations))

        dispatch(userRelationReducerActions.setUserRelations(userRelations.relations))
        const relations = userRelationSelectors.userRelationsFlattened(getState()) || []

        let newCurrentRelation = undefined
        if (!newCurrentRelation) {
            newCurrentRelation = getCurrentUserRelationFromLocalStorage(relations)
        }
        if (!newCurrentRelation) {
            newCurrentRelation = relations.find((r) => r.relationType === "User")!
        }
        dispatch(userRelationReducerActions.setCurrentRelation(newCurrentRelation))
        dispatch(ensureLastUsedRelationsThunk())
    }

export const ensureSwitchUserRelationsFromUrl =
    (): ThunkAction<Promise<boolean>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        const id = getQueryValueFromCurrentUrl("relationId")
        const relations = userRelationSelectors.userRelationsFlattened(getState()) || []
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())

        if (!currentUserRelation || !id) {
            return false
        }

        if (currentUserRelation.id === id) {
            return false
        }

        const newUserRelation = relations.find((r) => r.id === id)

        if (!newUserRelation) {
            return false
        }
        removeQueryValueFromCurrentUrl("relationId")
        dispatch(switchUserThunk(newUserRelation))
        return true
    }

export const ensureLastUsedRelationsThunk =
    (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        const lastUsedRelations = userRelationSelectors.lastUsedRelations(getState())

        if (lastUsedRelations.length === 0) {
            const userRelationsFlattened = userRelationSelectors.userRelationsFlattened(getState()) || []
            const currentUserData = userRelationsFlattened.find((r) => r.relationType === "User")!
            const lastUsedRelationsFromStorage = getLastUsedRelationsFromLocalStorage(currentUserData.id, userRelationsFlattened)

            if (lastUsedRelationsFromStorage) {
                //if any of id's in localstorage is non existent
                if (lastUsedRelationsFromStorage.length < 5) {
                    dispatch(userRelationReducerActions.initLastUsedRelations(userRelationsFlattened))
                } else {
                    dispatch(userRelationReducerActions.initLastUsedRelations(lastUsedRelationsFromStorage))
                }
            } else {
                setLastUsedRelationsToLocalStorage(currentUserData.id, userRelationsFlattened.map((r) => r.id).slice(0, 5))
                dispatch(userRelationReducerActions.initLastUsedRelations(userRelationsFlattened))
            }
        }
    }

export const addLastUsedRelationThunk =
    (relation: IUserRelationFlattened): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userRelationsFlattened = userRelationSelectors.userRelationsFlattened(getState()) || []
        const currentUserData = userRelationsFlattened.find((r) => r.relationType === "User")!

        await dispatch(userRelationReducerActions.addLastUsedRelation(relation.id))
        setLastUsedRelationsToLocalStorage(currentUserData.id, userRelationSelectors.lastUsedRelations(getState()))
    }

export const ensureUsersRelatedForOrganizationThunk =
    (force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation) {
            return
        }
        if (currentUserRelation.relationType === "User") {
            // Type User is not an organization
            return
        }
        const userRelations = userRelationSelectors.relationsForOrganization(getState(), currentUserRelation.id)
        if (!force && userRelations) {
            return
        }

        const relations = await dispatch(
            requestThunk<IRelationsForOrganization>(
                endpoints.Core.getUsersForOrganization(currentUserRelation.relationType, currentUserRelation.id)
            )
        )
        dispatch(userRelationReducerActions.setRelationsForOrganization(currentUserRelation.id, relations.users))
    }
