import { applyMiddleware, compose, createStore } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"
import { StoreState, createRootReducer } from "./StoreState"

export default function configureStore(initialState: StoreState) {
    const middleware = [thunk]

    return createStore(createRootReducer(), initialState, composeWithDevTools(applyMiddleware(...middleware)))
}
