import React from "react"
import { apiSelectors } from "../../store/reducers/apiReducer"
import { useSelector } from "react-redux"
import { IApiDocRoute } from "../../type/api/apiDocTypes"
import cn from "classnames"
import "./ApiRouteName.scss"

interface IApiRouteNameProps {
    route: IApiDocRoute
    alignRows?: boolean
}

const ApiRouteName = ({ route, alignRows }: IApiRouteNameProps) => {
    const routes = useSelector(apiSelectors.getApiRoutes)
    const docs = useSelector(apiSelectors.getApiDocs)

    if (!routes || !docs) {
        return null
    }

    const method = route.overrideExternalMethod || route.method || ""

    return (
        <div className={cn("api-route-name", alignRows && "align-rows")}>
            <span
                className={cn("method", {
                    post: method === "post",
                    get: method === "get",
                    put: method === "put",
                    delete: method === "delete",
                })}
            >
                {method.toUpperCase()}
            </span>{" "}
            <span>{route.pathTemplate}</span>
        </div>
    )
}

export default ApiRouteName
