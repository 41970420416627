import React, { useEffect, useState } from "react"
import "./ContentPageContainer.scss"
import { IContentItem, IContentGroup } from "../../type/doc/IContent"
import { contentReducerActions, contentSelectors } from "../../store/reducers/contentReducer"
import { useSelector } from "react-redux"
import { StoreState } from "../../store/StoreState"
import { sortAscending } from "../../util/arrayUtil"
import ContentRichText from "./ContentRichText"
import ContentAdder from "./ContentAdder"
import { getContentSortValue } from "../../services/contentService"
import useReduxDispatch from "../../store/useReduxDispatch"
import { addOrUpdateGroupContentItemsThunk } from "../../store/thunks/contentThunks"
import ContentWrapper from "./ContentWrapper"
import StandardButtonWithSpinner from "../buttons/StandardButtonWithSpinner"
import StandardButton from "../buttons/StandardButton"
import { render } from "node-sass"

interface IContentPageContainerProps {
    page: IContentGroup
}

const ContentPageContainer = ({ page }: IContentPageContainerProps) => {
    const dispatch = useReduxDispatch()
    const isInEditorMode = useSelector(contentSelectors.getIsInEditorMode)
    const items = useSelector((state: StoreState) => contentSelectors.getContentItemForParent(state, page.itemId)) || []
    const sorted = sortAscending(items, (arr) => getContentSortValue(arr))
    const lastItem = sorted.length === 0 ? undefined : sorted[sorted.length - 1]

    const renderContent = (item: IContentItem) => {
        if (item.type === "RichText") {
            return <ContentRichText className="content-page content-page-rich-text" item={item} />
        }
        return null
    }

    return (
        <div className="content-page-container-comp">
            {sorted.map((item, i) => {
                const itemBefore = i === 0 ? undefined : sorted[i - 1]
                const itemAfter = i === sorted.length - 1 ? undefined : sorted[i + 1]
                return (
                    <div key={item.locationInParent}>
                        {isInEditorMode && (
                            <ContentAdder
                                parentId={page.itemId}
                                sortValBefore={itemBefore ? getContentSortValue(itemBefore) : getContentSortValue(item) - 1000}
                                sortValAfter={getContentSortValue(item)}
                            />
                        )}
                        {isInEditorMode && (
                            <ContentWrapper
                                canMoveDown={!!itemAfter}
                                canMoveUp={!!itemBefore}
                                item={item}
                                moveDown={() => {
                                    if (!itemAfter) {
                                        return
                                    }
                                    dispatch(
                                        contentReducerActions.updateItem({
                                            ...item,
                                            locationInParent: getContentSortValue(itemAfter) + "",
                                        })
                                    )
                                    dispatch(
                                        contentReducerActions.updateItem({
                                            ...itemAfter,
                                            locationInParent: getContentSortValue(item) + "",
                                        })
                                    )
                                }}
                                moveUp={() => {
                                    if (!itemBefore) {
                                        return
                                    }
                                    dispatch(
                                        contentReducerActions.updateItem({
                                            ...item,
                                            locationInParent: getContentSortValue(itemBefore) + "",
                                        })
                                    )
                                    dispatch(
                                        contentReducerActions.updateItem({
                                            ...itemBefore,
                                            locationInParent: getContentSortValue(item) + "",
                                        })
                                    )
                                }}
                            >
                                {renderContent(item)}
                            </ContentWrapper>
                        )}
                        {!isInEditorMode && renderContent(item)}
                    </div>
                )
            })}
            {isInEditorMode && (
                <ContentAdder
                    parentId={page.itemId}
                    sortValBefore={lastItem ? getContentSortValue(lastItem) : 0}
                    sortValAfter={lastItem ? getContentSortValue(lastItem) + 1000 : 1000}
                />
            )}
        </div>
    )
}

export default ContentPageContainer
