import React from "react"
import cn from "classnames"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { IModalCallback } from "./modalTypes"
import "./BaseModal.scss"

interface IBaseModalProps {
    title?: string
    size?: string
    id?: string
    centered?: boolean
    noBordersInside?: boolean
    isOpen: boolean
    onClosed: () => void
    showCloseButton?: boolean
    children?: React.ReactNode
    modalClassName?: string
    autoFocus?: boolean
}

const BaseModal = ({
    title,
    showCloseButton,
    size,
    id,
    centered,
    noBordersInside,
    children,
    isOpen,
    modalClassName,
    autoFocus,
    onClosed,
    callback,
}: IBaseModalProps & IModalCallback) => {
    return (
        <Modal
            wrapClassName={cn(
                "swiipe-modal-container",
                {
                    "no-borders-inside": !!noBordersInside,
                },
                modalClassName
            )}
            backdrop={true}
            toggle={() => callback("ignored")}
            isOpen={isOpen}
            onClosed={onClosed}
            size={size}
            id={id}
            centered={centered}
            autoFocus={autoFocus}
        >
            {(title || showCloseButton) && (
                <ModalHeader className="swiipe-modal-header" toggle={undefined}>
                    {title}
                    {showCloseButton && (
                        <span className="icon icon-close-gray-light close-cross" onClick={() => callback("ignored")}></span>
                    )}
                </ModalHeader>
            )}
            {children}
        </Modal>
    )
}

export default BaseModal
