import React from "react"
import { IApiDocSchema } from "../../type/api/apiDocTypes"
import "./ApiRouteParam.scss"
import { getSchemaSubItems, IExtractedRouteParameter } from "../../services/apiRouteService"
import ApiRouteSubParamsContainer from "./ApiRouteSubParamsContainer"
import ResolvedContent from "../content/ResolvedContent"

interface IApiRouteParamProps {
    param: IExtractedRouteParameter
    path: string
    onSubParamsOpenChange: (path: string, isOpen: boolean) => void
    openParams: { [path: string]: boolean }
}

function getTypeName(schema: IApiDocSchema): React.ReactNode {
    if (schema.type === "array") {
        const subType = schema.items ? getTypeName(schema.items) : undefined
        return (
            <span className="array">
                {"Array<"}
                {subType && subType}
                {">"}
            </span>
        )
    }
    if (schema.type === "object") {
        let objName = extractObjectNameFromFullName(schema.componentName) ?? ""
        if (objName.startsWith("Api")) {
            objName = objName.substring(3)
        }
        objName = objName.replace("Dto", "")
        return objName ? <span className="component">{objName}</span> : <span className="object">Object</span>
    }
    if (schema.type === "string" && schema.enum && schema.enum.length > 0) {
        const objName = extractObjectNameFromFullName(schema.componentName)
        return objName ? (
            <span className="component">
                {"Enum<"}
                {objName}
                {">"}
            </span>
        ) : (
            <span className="component">Enum</span>
        )
    }
    return <span className={schema.type ?? ""}>{schema.type ?? ""}</span>
}

function extractObjectNameFromFullName(componentName: string | undefined): string | undefined {
    const splittedComponent = componentName?.split(".")

    if (!splittedComponent || splittedComponent.length == 0) {
        return undefined
    }

    const objName = splittedComponent[splittedComponent.length - 1]
    return objName
}

function getDescription(param: IExtractedRouteParameter) {
    const schema = param.schema
    if (!schema) {
        return ""
    }
    const baseDesc = (schema.description ?? "").trim()
    const enumValues = schema.enum || schema.items?.enum
    if (enumValues && enumValues.length > 0) {
        return (
            (baseDesc.endsWith(".") ? baseDesc : baseDesc + ".") +
            (baseDesc.length > 0 ? " " : "") +
            "Values:" +
            enumValues.map((e) => " [[" + e + "]]").join(" ")
        )
    }
    return baseDesc
}

// const logged: string[] = []
// function logParam(param: IExtractedRouteParameter) {
//     if (logged.includes(param.name)) {
//         return
//     }
//     logged.push(param.name)
//     console.log(param)
// }

const ApiRouteParam = ({ param, path, openParams, onSubParamsOpenChange }: IApiRouteParamProps) => {
    const subItems = getSchemaSubItems(param.schema)

    return (
        <div className="api-route-param">
            <div>
                {param.in === "path" && <span className="in">{param.in}</span>}
                <span className="name">{param.name}</span>
                {param.isRequired && <span className="required">Required</span>}
                {!param.isRequired && <span className="optional">Optional</span>}
                {param.schema?.type && <span className="type">{param.schema ? getTypeName(param.schema) : ""}</span>}
            </div>
            <div className="desc">
                <ResolvedContent content={getDescription(param)} />
            </div>
            {subItems.length > 0 && (
                <div className="sub-items">
                    <ApiRouteSubParamsContainer
                        path={path}
                        openParams={openParams}
                        onSubParamsOpenChange={onSubParamsOpenChange}
                        params={subItems}
                    />
                </div>
            )}
        </div>
    )
}

export default ApiRouteParam
